<template>
	<div>
		<div class="dF jSB">
			<h5>Set Up Signing Order</h5>
			<div class="dF" v-if="$mq !== 'lg'">
				<a-switch v-model="showSigningRoute">
					<a-icon slot="checkedChildren" type="check" />
					<a-icon slot="unCheckedChildren" type="close" />
				</a-switch>
				<span class="ml-2">
					Preview Signing Route
				</span>
			</div>
		</div>
		<a-divider />
		<div class="dF">
			<div class="f1" style="max-width:750px;" v-if="!showSigningRoute || $mq === 'lg'">

				<a-checkbox v-model="requiresRepReview" class="ml-4 mb-4">
					Requires Rep to Review Contract
				</a-checkbox>

				<template v-if="requiresRepReview">
                    <SigningParty v-model="reviewer" :repReviewer="requiresRepReview"/>
					<a-divider />
                </template>

				<template v-if="singerDetailsUpdated && step5.purchaserSide && step5.purchaserSide.length">
					<div v-for="(signer, signerI) in step5.purchaserSide" :key="signer.id">
						<SigningParty v-model="step5.purchaserSide[signerI]"
							:IDVerificationWorkflows="IDVerificationWorkflows" class="mb-3" />
					</div>
				</template>
				<p v-else class="text-danger">
					You have no one from {{ transaction.type === "purchase" ? "Purchaser" : "Tenant" }} side assigned.
				</p>

				<a-divider />

				<template v-if="singerDetailsUpdated && step5.vendorSide && step5.vendorSide.length">
					<template v-for="(signer, signerI) in step5.vendorSide">
						<SigningParty :choices="appSettings.participants" v-model="step5.vendorSide[signerI]"
							:key="signer + signerI" />
					</template>
				</template>

				<template v-if="singerDetailsUpdated && step5.others && step5.others.length">
					<a-divider />
					<div v-for="(signer, signerI) in step5.others" :key="signer.id + signerI">
						<SigningParty :choices="appSettings.participants" v-model="step5.others[signerI]"
							:additional="true" @delete="step5.others.splice(signerI, 1)" />
					</div>
				</template>

				<a-divider />
				<a-button icon="plus" type="primary" size="large" @click="addRecipient">Additional Recipients</a-button>
			</div>
			<div class="f1 dF fC aC" v-if="$mq === 'lg' || showSigningRoute">
				<div style="position:sticky; top:10px">
					<h4 class="text-center mt-2">Sign Route Preview</h4>
					<SignRoutePreview :data="signRoutePreview" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { generateContractPreview, isProductAvailable, getContractTemplateName } from './tools'
import { ruleReq, arrToObj } from 'bh-mod'
import { transformTransaction } from 'bh-mod/helper'
import SigningParty from '@/components/forms/SigningParty'
import SignRoutePreview from '@/components/forms/SignRoutePreview'
import moment from 'moment'

export default {
	components: { SigningParty, SignRoutePreview },
	props: ['transaction'],
	data: () => ({
		finalTransaction: {},
		envelopeFileKey: null,
		envelopeData: null,
		latestOrder: 1,
		showSigningRoute: false,
		packagedTransaction: {},
		requiresRepReview: false,
		step5: {
			purchaserSide: [],
			vendorSide: [],
			others: [],
		},
		IDVerificationWorkflows: [],
		singerDetailsUpdated: false
	}),

	methods: {
		ruleReq,
		async createEnvelope() {
			console.log('Creating envelope...');
			this.$store.commit('LOAD', true)
			let transaction = {
				...this.finalTransaction
			}

			if (transaction.type === 'lease') {
				transaction.price = transaction.offer.totalRent
			} else if (transaction.offer.purchasePrice) {
				transaction.price = transaction.offer.purchasePrice
			}

            const signers = [...this.signRoutePreview];

            if (this.requiresRepReview) {
                signers.splice(0, 1);
            }

			transaction.envelope = {
				name: getContractTemplateName(transaction, this.instance.productType) || this.currentContract.name,
				file: {
					template: this.currentContract.id,
					key: this.envelopeFileKey
				},
				signers: signers,
				requiresRepReview: this.requiresRepReview
			}

			let url = '/transactions/:instance'
			if (this.$route.params.id && transaction.id) {
				transaction.resend = true
				url = `/transactions/:instance/${transaction.id}`

				if (transaction.envelopes) {
					let mainEnvelope = transaction.envelopes.find(x => x.main)
					if (mainEnvelope) {
						transaction.envelope.id = mainEnvelope.id
						delete transaction.envelopes
					}
				}
			}

			let productId = this.instance.productType == 'lowrise' ? transaction.product.lot.id : transaction.product.unit.id;

			this.$api[this.$route.params.id && transaction.id ? 'put' : 'post'](`${url}${this.$route.params.id && transaction.id ? `?productId=${productId}${this.currentElevation ? '&unit=' + this.currentElevation : ''}` : ''}`, transaction)
				.then((resp) => {
					if (resp && resp.data && resp.data.id) {
						let tr = resp.data
						this.$store.commit('SET_TRANSACTION', { id: tr.id, value: tr })
						this.$router.push(`/transaction/${tr.id}`)
						this.$message.success('Document Sent for Signing')
					}
				})
				.catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while creating your transaction. Please try again.`))
					}
				})

				.finally(() => {
					this.$store.commit('LOAD', false)
					this.$store.commit('CLOSE_PREVIEW')

				})
		},
		async generatePreview() {
			if (this.$store.state.savingAsDraft) {
				// Adding singers details in the other obj to use in draft continue
				this.finalTransaction.other = {
					...this.finalTransaction.other,
					signers: this.signRoutePreview
				}
				this.$emit('done', { ...this.finalTransaction })
				return
			}
			const transaction = this.finalTransaction

			// removing extra key signers from other object
			if (transaction.other && transaction.other.signers) {
				delete transaction.other.signers
			}
			this.$store.commit('LOAD', true)
			if (!isProductAvailable(transaction, this)) {
				if (this.instance.productType == 'lowrise') {
					this.$message.error('Sorry this lot is no longer available. Please select another one.')
				} else {
					this.$message.error('Sorry this unit is no longer available. Please select another one.')
				}
				return
			}

			try {
				const { key, url } = await generateContractPreview(transaction, this)
				this.envelopeFileKey = key
				this.$store.commit('REVIEW_SEND', { preview: url, callback: this.createEnvelope })
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'An error occurred while generating your contract. Please try again.'))
				}
			}

			this.$store.commit('LOAD', false)
		},
		async validate() {
			this.transaction.signingParty = [...this.step5.vendorSide, ...this.step5.others];
			let transaction = { ...this.transaction }

			let error = false;
			transaction.signingParty.forEach((s) => {
				if (!s.name || !s.email) {
					error = true;
				}
			})

			if (error) {
				return this.$message.error(`Please fill up required field details to continue.`);
			}

			if (this.$store.state.appData.allSettings && this.$store.state.appData.allSettings.app && this.$store.state.appData.allSettings.app.options) {
				transaction.other.defaults = this.$store.state.appData.allSettings.app.options
			}

			transaction.offer.date = Date.now()
			transaction.offer.solicitorReviewDate = moment().add(7, 'days');

			transformTransaction(transaction)

			if (transaction.offer && transaction.offer.inclusions && transaction.offer.inclusions.length && !this.inclusionsTransformed) {
				let incs = arrToObj(this.appSettings.inclusions)
				transaction.offer.inclusions = transaction.offer.inclusions.map(incId => {
					if (incs[incId]) {
						return incs[incId].body || ''
					}
					return ''
				})
				this.inclusionsTransformed = true
			}

			this.finalTransaction = transaction
			this.generatePreview()
		},
		addRecipient() {
			this.step5.others.push({
				canBeRemoved: true,
				action: 'cc',
				canSign: false,
				vendor: true,
				id: Date.now(),
				name: '',
				order: this.latestOrder += 1,
				role: '',
				supportingText: '',
				email: '',
				note: '',
				choice: 'other',
				idVerificationWorkflowId: "N/A"
			})
		},

		async fetchIDVerificationWorkflows() {
			try {
				const { data } = await this.$api.get(`transactions/:instance/identity-verification-workflows`)
				this.IDVerificationWorkflows = data;
			} catch (err) {
				// Error handling
			}
		}
	},
	computed: {
		currentElevation() {
			return this.$store.state.appData.currentElevation
		},
		signRoutePreview() {
			let route = {}
			if (this.requiresRepReview) {
				route = {
					0: {
						order: 0,
						signers: [{
							...this.reviewer
						}]
					}
				}
			}

			this.step5.purchaserSide.forEach(p => {
				if (!p.name.trim() || !p.email.trim()) return
				if (!route[p.order]) route[p.order] = { order: p.order, signers: [] }
				route[p.order].signers.push({ ...p, idVerificationWorkflowId: p.idVerificationWorkflowId === 'N/A' ? null : p.idVerificationWorkflowId })
			})
			this.step5.vendorSide.forEach(p => {
				if (!p.name.trim() || !p.email.trim()) return
				if (!route[p.order]) route[p.order] = { order: p.order, signers: [] }
				route[p.order].signers.push({ ...p, idVerificationWorkflowId: p.idVerificationWorkflowId === 'N/A' ? null : p.idVerificationWorkflowId })
			})
			this.step5.others.forEach(p => {
				if (!p.name.trim() || !p.email.trim()) return
				if (!route[p.order]) route[p.order] = { order: p.order, signers: [] }
				route[p.order].signers.push({ ...p, idVerificationWorkflowId: p.idVerificationWorkflowId === 'N/A' ? null : p.idVerificationWorkflowId })
			})

			let routes = Object.values(route).sort((a, b) => a.order > b.order ? 1 : -1)

			return routes
		},
		appSettings() {
			return this.$store.state.appData.allSettings.app && this.$store.state.appData.allSettings.app.options
		},
		currentContract() {
			if (!this.transaction.contract) return {}
			return this.$store.state.appData.templates[this.transaction.template] || {};
		},
        doNotSendCopyTo() {
            return this.currentContract?.options?.doNotSendCopyTo || { purchaser: false, lawyer: false, agent: false, seller: false }
        },
		instance() {
			return this.$store.state.instance
		},
		user() {
			return this.$store.state.user.user
		},
		reviewer: {
			get() {
				const user = {
					id: this.user.id,
					order: 0,
					action: 'approve',
					name: this.user.firstName + ' ' + this.user.lastName,
					role: 'Reviewer',
					email: this.user.email
				}

				return user
			},
			set(val) {

			}
		}
	},
	created() {
		this.fetchIDVerificationWorkflows();

		let contract = this.currentContract
		let defaultParticipants = this.appSettings.participants || []
		let transaction = this.transaction

		let signingObj = {
			solicitor: false,
			agent: false,
			signingParty: false,
			purchasers: false,
			purchaserSide: true,
			action: 'cc',
			actionDisabled: false,
			disabled: false,
			other: true,
			predefined: true,
			id: Date.now(),
			name: '',
			role: '',
			supportingText: '',
			email: '',
			note: '',
			choice: 'other',
			idVerificationWorkflowId: "N/A"
		}

		let { pages = [] } = contract

		let maxPurchasers = 0
		let maxSigningParties = 0

		let allPurchasers = []
		let signingParties = []
		let allSigningParties = []

		let signingReps = {
			solicitor: false,
			agent: false,
		}

		let signingList = {
			purchasers: {},
			reps: {},
			signingParty: {},
		}

		const transactionType = this.transaction.type === "purchase" ? "Purchaser" : "Tenant";

		pages.forEach((page, pageI) => {
			let { fields = [] } = page

			fields.forEach(spot => {
				let { field, text } = spot
				let fV = field.value
				let fT = field.type

				let canSign = false
				if (fT === 'initial' || fT === 'sign' || fT === 'signedDate') canSign = true

				if (fV.includes('purchasers[]')) {
					if (text.purchasers > maxPurchasers) maxPurchasers = text.purchasers
					if (!allPurchasers.includes(text.purchasers)) allPurchasers.push(text.purchasers)
					if (fT === 'initial' || fT === 'sign' || fT === 'signedDate') {
						if (!signingParties.includes(text.purchasers)) signingParties.push(text.purchasers)
						if (transaction.purchasers[text.purchasers - 1] && transaction.purchasers[text.purchasers - 1].email) {
							if (!signingList.purchasers[text.purchasers]) {
								signingList.purchasers[text.purchasers] = {
									...signingObj,
									canSign,
									actionDisabled: true,
									disabled: true,
									order: 1,
									id: `${transactionType}${text.purchasers},`,
									action: canSign ? 'sign' : 'cc',
									name: '',
									role: `${transactionType} #${text.purchasers}`,
									supportingText: `${transactionType} #${text.purchasers}`,
									email: '',
									purchasers: text.purchasers,
									signLocations: {}
								}
							}

							signingList.purchasers[text.purchasers].signLocations[spot.id] = {
								...spot,
								name: transaction.purchasers[text.purchasers - 1].fullName,
								email: transaction.purchasers[text.purchasers - 1].email,
								phone: transaction.purchasers[text.purchasers - 1].phone,
								page: pageI,
								file: 0,
							}

						}
					}
				}

				if (fV.includes('signingParty[]')) {
					if (text.signingParty > maxSigningParties) maxSigningParties = text.signingParty
					if (!allSigningParties.includes(text.signingParty)) allSigningParties.push(text.signingParty)
					if (fT === 'initial' || fT === 'sign' || fT === 'signedDate') {
						if (!signingParties.includes(text.signingParty)) signingParties.push(text.signingParty)
						if (!signingList.signingParty[text.signingParty]) {
							signingList.signingParty[text.signingParty] = {
								...signingObj,
								canSign,
								actionDisabled: true,
								disabled: false,
								order: 1,
								signingParty: text.signingParty,
								id: 'signingParty' + text.signingParty,
								action: canSign ? 'sign' : 'cc',
								name: '',
								role: text.signingParty === 1 ? `Main Signing Party` : `Additional Signing Party #${text.signingParty - 1}`,
								supportingText: text.signingParty === 1 ? `Main Signing Party` : `Additional Signing Party #${text.signingParty - 1}`,
								email: '',
								signLocations: {}
							}
						}

						signingList.signingParty[text.signingParty].signLocations[spot.id] = {
							...spot,
							page: pageI,
							file: 0,
						}
					}
				}

				if (fT === 'initial' || fT === 'sign' || fT === 'signedDate') {
					if (field.value.includes('reps.agent')) {
						signingReps.agent = true
						if (transaction.reps && transaction.reps.agent && transaction.reps.agent.fullName && transaction.reps.agent.email) {
							if (!signingList.reps.agent) {
								signingList.reps.agent = {
									...signingObj,
									canSign,
									actionDisabled: false,
									disabled: true,
									order: 1,
									id: 'agent',
									action: canSign ? 'sign' : 'cc',
									name: '',
									role: `${transactionType}'s Agent`,
									supportingText: `${transactionType}'s Agent`,
									email: '',
									signLocations: {}
								}
							}
							signingList.reps.agent.signLocations[spot.id] = {
								...spot,
								name: transaction.reps.agent.fullName,
								email: transaction.reps.agent.email,
								phone: transaction.reps.agent.phone,
								page: pageI,
								file: 0,
							}
						}
					}

					if (field.value.includes('reps.solicitor')) {
						signingReps.solicitor = true
						if (transaction.reps && transaction.reps.solicitor && transaction.reps.solicitor.fullName && transaction.reps.solicitor.email) {
							if (!signingList.reps.solicitor) {
								signingList.reps.solicitor = {
									...signingObj,
									canSign,
									actionDisabled: false,
									disabled: true,
									order: 1,
									id: 'solicitor',
									action: canSign ? 'sign' : 'cc',
									name: '',
									role: `${transactionType}'s Lawyer`,
									supportingText: `${transactionType}'s Lawyer`,
									email: '',
									signLocations: {},
								}
							}

							signingList.reps.solicitor.signLocations[spot.id] = {
								...spot,
								name: transaction.reps.solicitor.fullName,
								email: transaction.reps.solicitor.email,
								phone: transaction.reps.solicitor.phone,
								page: pageI,
								file: 0,
							}
						}
					}
				}
			})
		})

		let purchaserSide = []

		purchaserSide = transaction.purchasers.map((purchaser, purchaserI) => {
			if (signingList.purchasers[purchaserI + 1]) {
				signingList.purchasers[purchaserI + 1] = {
					...signingList.purchasers[purchaserI + 1],
					name: purchaser.fullName,
					id: `${transactionType}${purchaserI + 1}_sign`,
					supportingText: `${transactionType} #${purchaserI + 1}`,
					role: `${transactionType} #${purchaserI + 1}`,
					order: this.latestOrder,
					email: purchaser.email,
					phone: purchaser.phone,
					country: purchaser.country,
				}
				return signingList.purchasers[purchaserI + 1]
			}

			return {
				...signingObj,
				canSign: false,
				actionDisabled: false,
				disabled: true,
				order: this.latestOrder,
				id: `${transactionType}${purchaserI + 1}_notsign`,
				action: this.doNotSendCopyTo.purchaser ? 'none' : 'cc',
				name: purchaser.fullName,
				role: `${transactionType} #${purchaserI + 1}`,
				supportingText: `${transactionType} #${purchaserI + 1}`,
				email: purchaser.email,
				phone: purchaser.phone,
				country: purchaser.country,
				purchasers: purchaserI + 1,
			}
		})

		if (transaction.reps && transaction.reps.agent && transaction.reps.agent.email) {
			let person = transaction.reps.agent
			let canSign = Boolean(signingList.reps.agent)

			let agent = {
				...signingList.reps.agent,
				actionDisabled: false,
				disabled: true,
				canBeIgnored: true,
				order: this.latestOrder += 1,
				id: 'agent',
				canSign,
				action: canSign ? 'sign' : this.doNotSendCopyTo.agent ? 'none' : 'cc',
				name: person.firstName + ' ' + person.lastName,
				role: `${transactionType}'s Agent`,
				supportingText: `${transactionType}'s Agent`,
				email: person.email,
				phone: person.phone,
				idVerificationWorkflowId: "N/A"
			}
			purchaserSide.push(agent)
		}

		if (transaction.reps && transaction.reps.solicitor && transaction.reps.solicitor.email) {
			let person = transaction.reps.solicitor
			let canSign = Boolean(signingList.reps.solicitor)

			let solicitor = {
				...signingList.reps.solicitor,
				other: false,
				canBeIgnored: true,
				id: 'solicitor',
				actionDisabled: false,
				disabled: true,
				order: this.latestOrder += 1,
				canSign,
				action: canSign ? 'sign' : this.doNotSendCopyTo.lawyer ? 'none' : 'cc',
				name: person.firstName + ' ' + person.lastName,
				role: `${transactionType}'s Lawyer`,
				supportingText: `${transactionType}'s Lawyer`,
				email: person.email,
				phone: person.phone,
				idVerificationWorkflowId: "N/A"
			}

			purchaserSide.push(solicitor)
		}

		allSigningParties.forEach((sP, sPi) => {
			let obj = signingObj
			let canSign = false
			if (signingList.signingParty[sP]) {
				obj = signingList.signingParty[sP]
				canSign = true
			}
			let party = {
				...obj,
				roleDisabled: true,
				canSign,
				disabled: false,
				vendor: true,
				order: this.latestOrder += 1,
				id: Date.now() + sPi,
				action: canSign ? 'sign' : this.doNotSendCopyTo.seller ? 'none' : 'cc',
				name: '',
				email: '',
				supportingText: sP === 1 ? 'Main Signing Party' : `Additional Signing Party #${sP - 1}`,
				role: sP === 1 ? 'Main Signing Party' : `Additional Signing Party #${sP - 1}`,
				choice: 'other',
				actionDisabled: false,
			}

			if (defaultParticipants && defaultParticipants[sPi]) {
				party = {
					...party,
					choice: defaultParticipants[sPi].email,
					name: defaultParticipants[sPi].name,
					role: defaultParticipants[sPi].role || (sP === 1 ? `Main Signing Party` : `Additional Signing Party #${sP - 1}`),
					email: defaultParticipants[sPi].email,
					company: defaultParticipants[sPi].company,
					id: defaultParticipants[sPi].id || party.id
				}
			}

			this.step5.vendorSide.push(party)
		})
		this.step5.purchaserSide = purchaserSide
	},

	mounted() {
		this.$emit('setFn', this.validate)

		if (this.$route.params.id || this.$route.query.draft) {
			if (this.transaction.envelopes && this.transaction.envelopes[0]) {
				this.requiresRepReview = !!this.transaction.envelopes[0].requiresRepReview
				let purchaserSide = this.step5.purchaserSide.map(x => {
					if (this.transaction.other.signers) {
						let findSignerIndex = null
						let findSigner = this.transaction.other.signers.find(y => {
							if (y.signers && y.signers.length) {
								const signerIndex = y.signers.findIndex(s => s.id === x.id)
								if (signerIndex > -1) {
									findSignerIndex = signerIndex
									return true;
								}
							}
						})
						if (findSigner) {
							x = { ...x, ...findSigner.signers[findSignerIndex] }
						}
					}

					return x
				})
				this.$set(this.step5, 'purchaserSide', purchaserSide)
			}
			if (this.transaction.other && this.transaction.other.signers && this.transaction.other.signers.length) {
				this.requiresRepReview = !!this.transaction.other.requiresRepReview
				let purchaserSide = this.step5.purchaserSide.map(x => {
					if (this.transaction.other.signers) {
						let findSignerIndex = null
						let findSigner = this.transaction.other.signers.find(y => {
							if (y.signers && y.signers.length) {
								const signerIndex = y.signers.findIndex(s => s.id === x.id)
								if (signerIndex > -1) {
									findSignerIndex = signerIndex
									return true;
								}
							}
						})
						if (findSigner) {
							x = { ...x, ...findSigner.signers[findSignerIndex] }
						}
					}

					return x
				})
				this.$set(this.step5, 'purchaserSide', purchaserSide)
			}
			if (this.transaction.signingParty && this.transaction.signingParty.length) {
				let mainSigningParty = this.transaction.signingParty.filter(sp => !sp.canBeRemoved)
				let others = this.transaction.signingParty.filter(sp => sp.canBeRemoved)

				if (mainSigningParty && this.step5.vendorSide && (mainSigningParty.length <= this.step5.vendorSide.length)) {
					this.$set(this.step5, 'vendorSide', mainSigningParty)
				}
				if (others && others.length) {
					this.$set(this.step5, 'others', others)
				}
			}
		}

		// Using this flag to get saved value of purchaserSide, vendorSide and others in the draft case
		this.singerDetailsUpdated = true;
	}
}
</script>

<style lang="scss">
.signer-action-icon {
	position: absolute;
	top: 10px;
	right: 10px;
	background: white;
	pointer-events: none;
	padding: 5px;
	border-radius: 50%;
	box-shadow: 0 2px 10px rgba(100, 100, 100, 0.2);
	font-size: 10px;
}
</style>
